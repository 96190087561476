import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "gatsby";
import styled from "styled-components";
import SEO from "../components/seo";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import AppButton from "../components/common/button";
import { LINKS } from "../constants/links";
import { getQueryParam } from "../utils/queryParams";
import { AppContainer } from "../components/common/appContainer";

const StyledButtonWrapper = styled.div`
	text-align: center;
`;

const PaymentFailed = () => {
	const { t } = useTranslation();
	const orderId = getQueryParam("orderId");

	return (
		<>
			<SEO title={t("commentAndRatingSend.title")} />
			<AppContainer>
				<AppPageHeader>{t("commentAndRatingSend.header")}</AppPageHeader>
				<AppLeadingText>{t("commentAndRatingSend.description")}</AppLeadingText>
				<StyledButtonWrapper>
					<AppButton as={Link} to={LINKS.HOMEPAGE}>
						{t("contactUsSend.homepageButton")}
					</AppButton>
					<AppButton
						as={Link}
						style={{
							marginTop: "50px",
						}}
						to={LINKS.ORDER + orderId}
					>
						{t("order.billHeader")}
					</AppButton>
				</StyledButtonWrapper>
			</AppContainer>
		</>
	);
};

export default PaymentFailed;
